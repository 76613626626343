<template>
  <div>
    <secure-page
      v-if="!isSecureCheck && !loadingBusiness"
      @onLogin="
        getAttractions();
        isSecureCheck = true;
      "
    />
    <div
      style="min-height: 80vh"
      v-if="showSelectAttraction && isSecureCheck && !loadingBusiness"
    >
      <v-row class="font-weight-bold my-5 ml-1" style="color: #6abaa4">{{'admin_prebooking_choose_attraction' | localize}}</v-row>

      <v-row v-for="attr in attractions" :key="attr.uid">
        <v-btn
          text
          style="text-transform: none"
          @click="
            attraction = attr;
            showSelectAttraction = false;
          "
          >{{ attr.title }}</v-btn
        >
      </v-row>
    </div>
    <prebooking-panel
      v-if="isSecureCheck && !loadingBusiness && !showSelectAttraction"
      @onInvalidToken="onInvalidToken"
      @chooseAnotherAttr="chooseAnotherAttr"
      :attraction="attraction"
      :isManyAttraction="attractions.length <= 1 ? false : true"
    />
    <div
      class="d-flex align-center"
      v-if="loadingBusiness"
      style="min-height: 80vh"
    >
      <v-progress-circular
        v-if="loadingBusiness"
        class="mx-auto"
        indeterminate
        color="#E62076"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import SecurePage from "./SecurePage.vue";
import PrebookingPanel from "./PrebookingPanelComponent.vue";
import BusinessService from "@/requests/Business/BusinessService.js";

export default {
  components: {
    SecurePage,
    PrebookingPanel,
  },
  data: () => ({
    isSecureCheck: false,
    loadingBusiness: false,
    attraction: {},
    showSelectAttraction: false,
    attractions: [],
  }),
  mounted() {
    if (localStorage.businessToken) {
      this.isSecureCheck = true;
      this.getAttractions();
    }
  },
  methods: {
    chooseAnotherAttr() {
      this.attraction = {};
      localStorage.removeItem("choosedAttr");
      this.showSelectAttraction = true;
    },
    async getAttractions() {
      try {
        this.loadingBusiness = true;
        let attractions = await BusinessService.getAttractions();
        if (attractions.result.length) {
          this.attractions = attractions.result.sort(function (a, b) {
            var textA = a.title.toUpperCase();
            var textB = b.title.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
          if (this.attractions.length > 1) {
            if (localStorage.choosedAttr) {
              this.attraction = JSON.parse(localStorage.choosedAttr);
              console.log(this.attraction);
            } else {
              this.showSelectAttraction = true;
            }
          } else {
            this.attraction = attractions.result[0];
          }
        } else {
          setTimeout(() => {
            this.onInvalidToken();
          }, 5000);
        }
        this.loadingBusiness = false;
      } catch (e) {
        this.onInvalidToken();
        this.loadingBusiness = false;
      }
    },
    async onInvalidToken() {
      if (localStorage.businessToken) {
        try {
          await BusinessService.logoutBusiness();
        } catch (e) {
          localStorage.removeItem("choosedAttr");
          localStorage.removeItem("businessToken");
          this.isSecureCheck = false;
        }
      }
      localStorage.removeItem("choosedAttr");
      localStorage.removeItem("businessToken");
      this.isSecureCheck = false;
    },
  },
  watch: {
    attraction: {
      handler() {
        if (this.attraction.uid) {
          localStorage.choosedAttr = JSON.stringify(this.attraction);
        } else {
          localStorage.removeItem("choosedAttr");
        }
      },
    },
  },
};
</script>