<template>
  <v-main>
    <v-container>
      <prebooking-panel-main />
    </v-container>
  </v-main>
</template>

<script>
import PrebookingPanelMain from '@/components/PrebookingPanel/PrebookingPanelMain'

export default {
    components: {
        PrebookingPanelMain,
    }
}
</script>