import axios from 'axios'
// import store from '@/store';

const api = process.env.VUE_APP_API_IOP

export default {
	async loginBusiness(payload) {
		const response = await axios.post(`${api}/auth/login`, payload)
		return response?.data
	},
	async logoutBusiness() {
		const response = await axios.post(`${api}/auth/logout`, {}, {
			headers: {
				Authorization: `Bearer ${localStorage.businessToken}`,
			}
		})
		return response?.data
	},
	async getAttractions() {
		const response = await axios.get(`${api}/business/attractions`, {
			headers: {
				Authorization: `Bearer ${localStorage.businessToken}`,
			}
		})
		return response?.data
	},

}