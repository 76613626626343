<template>
  <div>
    <v-row
      justify="space-between"
      no-gutters
      class="mb-4"
      style="font-size: 32px; color: #212121; font-weight: 700"
    >
      <v-col v-if="attraction.uid"
        >{{ attraction.title }}
        <div v-if="isManyAttraction">
          <v-btn
            text
            style="text-transform: none"
            color="#6abaa4"
            @click="$emit('chooseAnotherAttr')"
            class="ma-0 pa-0"
          >
            {{ "admin_prebooking_choose_another_attraction" | localize }}
          </v-btn>
        </div></v-col
      >
      <v-col cols="auto" v-if="attraction.uid">
        <v-btn
          dark
          style="
            width: 148px;
            height: 32px;
            background: #6abaa4;
            text-transform: none !important;
            border-bottom: 1px solid #4e8777;
          "
          @click="
            addWithDates = false;
            editMode = false;
            slotDialogVisible = true;
          "
        >
          {{ "admin_new_slot_button" | localize }}
        </v-btn>
      </v-col>
    </v-row>
    <v-card
      class="pa-4 pt-0"
      v-if="attraction.uid"
      :loading="eventsLoading"
      :loader-height="3"
    >
      <v-sheet height="74">
        <v-toolbar flat class="pt-2">
          <v-btn
            outlined
            style="text-transform: none"
            class="mr-4"
            color="grey darken-2"
            @click="setToday"
          >
            {{ "admin_prebooking_today_label" | localize }}
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-radio-group
            class="mr-4"
            v-model="updatingRadio"
            hide-details
            row
            mandatory
          >
            <template v-slot:label>
              <div style="font-size: 16px !important; font-weight: 700">
                {{ "refresh_label" | localize }}
              </div>
            </template>
            <v-radio value="Automatic" :label="'refresh_auto_label' | localize">
            </v-radio>
            <v-radio value="Manual" :label="'refresh_manual_label' | localize">
            </v-radio>
          </v-radio-group>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="grey darken-2"
                style="text-transform: none"
                v-bind="attrs"
                v-on="on"
              >
                <span>{{ typeToLabel[type] | localize }}</span>
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>{{
                  "admin_prebooking_day_label" | localize
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>{{
                  "admin_prebooking_week_label" | localize
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>{{
                  "city_passes_label_month" | localize
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          v-model="focus"
          style="white-space: pre-line"
          color="#6ABAA4"
          :events="events"
          :locale="locale"
          :type="type"
          :value="selectedDate"
          :first-time="'07:00'"
          :interval-count="17"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @mousedown:event="startDrag"
          @mousedown:time="startTime"
          @mousemove:time="mouseMove"
          @mouseup:time="endDrag"
          @change="updateRange"
        >
          <template v-slot:event="{ event }">
            <div
              v-if="
                Math.floor(
                  Math.abs(new Date(event.start) - new Date(event.end)) /
                    1000 /
                    60
                ) >= 50 && type != 'month'
              "
            >
              <v-row
                class="pl-1"
                no-gutters
                style="font-weight: 600; font-height: 10px"
              >
                <v-col> {{ "attractions_adult" | localize }} </v-col>
                <v-col> {{ event.booked_adult }}/{{ event.adult }} </v-col>
              </v-row>
              <v-row
                class="pl-1 pt-1"
                no-gutters
                style="font-weight: 600; font-height: 10px"
              >
                <v-col> {{ "cart_label_child" | localize }} </v-col>
                <v-col> {{ event.booked_kid }}/{{ event.kid }} </v-col>
              </v-row>
            </div>
            <div v-else>
              <v-row
                class="pl-1"
                no-gutters
                style="font-weight: 600; font-height: 10px"
              >
                <v-col>
                  {{ "attractions_adult" | localize }}
                  {{ event.booked_adult }}/{{ event.adult }}
                </v-col>
                <v-col class="ml-3">
                  {{ "cart_label_child" | localize }} {{ event.booked_kid }}/{{
                    event.kid
                  }}
                </v-col>
              </v-row>
            </div>
          </template>
        </v-calendar>
        <!-- @change="updateRange" -->
      </v-sheet>
    </v-card>
    <div
      class="mt-15 pt-15 text-center align-center justify-center"
      v-if="!attraction.uid"
      style="min-height: 80vh"
    >
      <div>This business does not have attraction yet</div>

      <div class="mt-5">
        <v-btn
          color="#E62076"
          text
          style="height: 44px; text-transform: none"
          @click="$emit('onInvalidToken')"
        >
          {{ "profile_button_log_out" | localize }}
        </v-btn>
      </div>
    </div>
    <v-row class="mt-5" v-if="attraction.uid">
      <v-spacer />
      <div>
        <v-btn
          color="#E62076"
          text
          style="height: 44px; text-transform: none"
          @click="$emit('onInvalidToken')"
        >
          {{ "profile_button_log_out" | localize }}
        </v-btn>
      </div>
    </v-row>
    <slot-dialog
      v-if="slotDialogVisible"
      :visible="slotDialogVisible"
      :event="selectedEvent"
      :editMode="editMode"
      :addWithDates="addWithDates"
      :attraction="attraction"
      @close="addWithDates ? clearNewEvent() : (slotDialogVisible = false)"
      @modifiedEvents="
        checkChangeCalendar();
        slotDialogVisible = false;
      "
    />
  </div>
</template>

<script>
import slotDialog from "./slotDialog.vue";
import eventsService from "@/requests/Events/eventsService.js";
import userSettingsMixin from "@/mixins/userSettingsMixin.js";
import dateMixin from "../../mixins/dateMixin";
export default {
  components: {
    slotDialog,
  },
  props: {
    attraction: {
      require: true,
    },
    isManyAttraction: {
      require: true,
    },
  },
  mixins: [userSettingsMixin, dateMixin],
  data: () => ({
    selectedEvent: {},
    editMode: false,
    addWithDates: false,
    slotDialogVisible: false,
    focus: "",
    type: localStorage.calendarType,
    typeToLabel: {
      month: "city_passes_label_month",
      week: "admin_prebooking_week_label",
      day: "admin_prebooking_day_label",
    },
    events: [],
    selectedDate: new Date().toISOString().substr(0, 10),
    dragEvent: null,
    dragStart: null,
    createEvent: null,
    createStart: null,
    extendOriginal: null,
    updatingRadio: "Automatic",
    updateTimeout: null,
    eventsLoading: false,
  }),
  mounted() {
    let types = ["week", "month", "day"];
    if (
      localStorage.calendarType &&
      types.includes(localStorage.calendarType)
    ) {
      this.type = localStorage.calendarType;
    } else {
      this.type = "week";
    }
    if (this.attraction.uid) {
      this.$refs.calendar.checkChange();
    }
  },
  destroyed() {
    clearTimeout(this.updateTimeout);
  },
  methods: {
    checkChangeCalendar() {
      let period = {
        start: this.$refs.calendar.lastStart.date,
        end: this.$refs.calendar.lastEnd.date,
      };
      this.getEvents(period.start, period.end);
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    clearNewEvent() {
      const i = this.events.indexOf(this.createEvent);
      if (i !== -1) {
        this.events.splice(i, 1);
      }

      this.createEvent = null;
      this.addWithDates = false;
      this.slotDialogVisible = false;
    },
    startDrag({ event, timed }) {
      if (event && timed) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    startTime(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;

        this.dragTime = mouse - start;
      } else {
        this.createStart = this.roundTime(mouse);
        console.log(this.createStart);
        this.createEvent = {
          name: "",
          uuid: "",
          booked_kid: 0,
          kid: "0",
          adult: "0",
          booked_adult: 0,
          attraction_uuid: "",
          color: "#6ABAA4",
          start: this.createStart,
          end: this.createStart + 900000,
          timed: true,
          repeat: null,
        };
        console.log(this.createEvent.end);
        this.events.push(this.createEvent);
      }
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;

        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);

        this.createEvent.start = min;
        this.createEvent.end = max + 900000;
      }
    },
    endDrag() {
      if (this.createEvent && this.createStart !== null) {
        setTimeout(() => {
          this.editMode = false;
          this.addWithDates = true;
          this.selectedEvent = { ...this.createEvent };
          this.slotDialogVisible = true;
        }, 500);
      }
      this.dragTime = null;
      this.dragEvent = null;
      //this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    roundTime(time, down = true) {
      const roundTo = 15; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }

      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    async getEvents(start, end) {
      try {
        this.eventsLoading = true;
        let data = {
          attraction_uuid: this.attraction.uid,
          started_at: start + " 00:00:00",
          ended_at: end + " 23:59:59",
        };
        let res = await eventsService.getEventsAdmin(data);
        let events = [];
        res.result.forEach((el) => {
          events.push({
            start: `${this.formatToISO(el.started_at)}`,
            end: `${this.formatToISO(el.ended_at)}`,
            timed: true,
            color:
              new Date(el.started_at) > new Date() ? "#6ABAA4" : "#6abaa582",
            name: el.uuid,
            uuid: el.uuid,
            booked_kid: el.booked_kid,
            kid: el.kid,
            adult: el.adult,
            booked_adult: el.booked_adult,
            attraction_uuid: el.attraction_uuid,
            repeat: el.repeat,
          });
        });
        console.log(events);
        this.events = events;
        this.eventsLoading = false;
        if (this.updatingRadio == "Automatic") {
          clearTimeout(this.updateTimeout);

          this.updateTimeout = setTimeout(() => {
            this.getEvents(start, end);
          }, 30000);
        }
      } catch (e) {
        this.eventsLoading = false;
        alert(e);
      }
    },
    showEvent({ nativeEvent, event }) {
      console.log("show", nativeEvent, event);

      this.selectedEvent = event;
      this.addWithDates = false;
      this.editMode = true;
      this.slotDialogVisible = true;
    },
    updateRange({ start, end }) {
      this.getEvents(start.date, end.date);
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)];
    },
  },
  watch: {
    type: {
      handler() {
        if (this.type) {
          localStorage.calendarType = this.type;
        } else {
          localStorage.removeItem("calendarType");
        }
      },
    },
    updatingRadio: {
      handler() {
        if (this.updatingRadio == "Manual") {
          clearTimeout(this.updateTimeout);
        } else {
          this.updateTimeout = setTimeout(() => {
            let period = {
              start: this.$refs.calendar.lastStart.date,
              end: this.$refs.calendar.lastEnd.date,
            };
            this.getEvents(period.start, period.end);
          }, 30000);
        }
      },
    },
  },
};
</script>

<style>
.v-calendar-daily_head-day-label .v-btn .v-btn__content {
  color: #fff !important;
}
.v-calendar-daily_head-day-label .transparent .v-btn__content {
  color: #212121 !important;
}
</style>
