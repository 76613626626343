<template>
  <div style="min-height: 100vh">
    <v-row justify="center" align="center" style="min-height: 80vh">
      <v-col cols="12" lg="5" md="5" xl="5" sm="5">
        <v-form @submit.prevent="checkPass()">
          <v-card class="pa-5">
            <v-card-text>
              <v-row no-gutters>
                <v-col class="text-center">
                  <img src="@/assets/new-logo-gdl.svg" width="300px" />
                </v-col>
              </v-row>
              <v-text-field
                class="mt-6"
                v-model="user.email"
                :label="'faq_placeholder_email' | localize"
                dense
                type="email"
                outlined
                persistent-placeholder
                :error-messages="emailError | localize"
                @input="messages = ''"
              >
              </v-text-field>
              <v-text-field
                v-model="user.password"
                :label="'sign_in_placeholder_password' | localize"
                dense
                type="password"
                outlined
                persistent-placeholder
                :error-messages="passwordError | localize"
                :hide-details="!passwordError.length"
                @input="messages = ''"
              >
              </v-text-field>
              <span style="color: red" v-if="messages">{{
                messages | localize
              }}</span>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn dark color="#e62076" @click="checkPass()">{{
                "sign_in_heading_sign_in" | localize
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import BusinessService from "@/requests/Business/BusinessService.js";

export default {
  mixins: [validationMixin],
  data: () => ({
    messages: "",
    user: {},
  }),
  validations: {
    user: {
      password: {
        required,
      },
      email: {
        email,
        required,
      },
    },
  },
  methods: {
    async checkPass() {
      try {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          var form = new FormData();
          form.append("email", this.user.email);
          form.append("password", this.user.password);
          form.append("type", "Business");
          form.append(
            "activation_url",
            `https://business.dev.hoozaround.com` + "/activation/"
          );
          let res = await BusinessService.loginBusiness(form);

          if (
            res.result &&
            res.messages.success !== "User not activated" &&
            !res.messages.error
          ) {
            localStorage.businessToken = res.result.token;
            localStorage.setItem("calendarType", "week");
            this.$emit("onLogin");
          } else {
            this.messages = "invalid_credentials";
          }
        }
      } catch (e) {
        console.log(e.response);
        if (e.response.status == 404) {
          this.messages = "invalid_credentials";
        }
      }
    },
  },
  computed: {
    emailError() {
      const errors = [];
      if (!this.$v.user.email.$dirty) {
        return errors;
      }
      !this.$v.user.email.required &&
        errors.push("sign_up_validation_email_required");
      if (!this.$v.user.email.email) {
        errors.push("reset_password_validation_incorrect_email");
        return errors;
      }
      return errors;
    },
    passwordError() {
      const errors = [];
      if (!this.$v.user.password.$dirty) {
        return errors;
      }
      !this.$v.user.password.required &&
        errors.push("sign_up_validation_password_required");
      return errors;
    },
  },
};
</script>