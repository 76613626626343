<template>
  <v-dialog v-model="visibility" max-width="560px" persistent>
    <v-card style="position: relative">
      <v-icon
        style="position: absolute; right: 12px; top: 12px"
        large
        @click="visibility = false"
        >mdi-close</v-icon
      >
      <v-card-title v-if="!editMode">
        {{ "admin_new_slot_button" | localize }}</v-card-title
      >
      <v-card-title v-else>
        {{ "admin_prebooking_edit_slot_label" | localize }}
      </v-card-title>
      <v-card-text>
        <v-row class="mt-5">
          <v-col class="py-0">
            <v-menu
              v-model="dateMenu"
              :close-on-content-click="false"
              :menu-props="{ bottom: true, offsetY: true }"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <!-- slot.date.substr(0, 4),
                slot.date.substr(5, 2) - 1,
                slot.date.substr(8, 2) -->
                <v-text-field
                  :value="
                    slot.date
                      ? new Date(slot.date).toLocaleDateString('en-gb', {
                          timeZone: 'Europe/London',
                        })
                      : ''
                  "
                  :label="'date_placeholder' | localize"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :error-messages="dateError"
                  @input="message = ''"
                  :disabled="editMode && new Date(event.start) < new Date()"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="slot.date"
                @input="dateMenu = false"
                :min="
                  new Date(event.start) > new Date()
                    ? new Date().toISOString().substr(0, 10)
                    : undefined
                "
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col class="py-0">
            <v-menu
              ref="menu"
              v-model="menuTime"
              :close-on-content-click="false"
              offset-y
              bottom
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <div @click="menuTime = true">
                  <v-select
                    v-model="times"
                    :items="times"
                    multiple
                    :persistent-placeholder="menuTime"
                    :label="'time_placeholder' | localize"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :disabled="editMode && new Date(event.start) < new Date()"
                    :error-messages="
                      timeStartError.length
                        ? timeStartError
                        : timeEndError.length
                        ? timeEndError
                        : ''
                    "
                  >
                    <template #selection="{ item }">
                      <v-chip
                        small
                        @click="menuTime = true"
                        :disabled="
                          editMode && new Date(event.start) < new Date()
                        "
                      >
                        {{ item }}
                      </v-chip>
                    </template>
                  </v-select>
                </div>
              </template>
              <v-card class="pa-3">
                <v-row justify="space-around" align="center">
                  <v-col>
                    <h2>{{ "start_time_label" | localize }}</h2>
                    <v-time-picker
                      v-model="slot.start_time"
                      :min="
                        slot.date == new Date().toISOString().substring(0, 10)
                          ? `${new Date().getHours()}:${new Date().getMinutes()}`
                          : undefined
                      "
                      :max="slot.end_time"
                      @input="message = ''"
                    ></v-time-picker>
                  </v-col>
                  <v-col>
                    <h2>{{ "end_time_label" | localize }}</h2>
                    <v-time-picker
                      v-model="slot.end_time"
                      :min="slot.start_time"
                      @input="message = ''"
                    ></v-time-picker>
                  </v-col>
                </v-row>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
        <v-row class="my-8">
          <v-col class="py-0">
            <v-row style="height: 32px" align="center" justify="space-between">
              <v-col cols="auto" class="py-0">{{
                "adult_attendees_label" | localize
              }}</v-col>
              <v-col cols="auto" class="py-0">
                <v-text-field
                  class="input-count py-0 my-0"
                  type="number"
                  style="width: 35px"
                  v-model="slot.adult"
                  hide-details
                  @keypress="isNumber"
                  :error-messages="adultError"
                  :min="editMode ? Number(event.booked_adult) : 0"
                  :disabled="editMode && new Date(event.start) < new Date()"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row
              justify="space-between"
              class="mt-5"
              style="height: 32px"
              align="center"
            >
              <v-col cols="auto" class="py-0"
                ><div>{{ "child_attendees_label" | localize }}</div></v-col
              >
              <v-col cols="auto" class="py-0">
                <v-text-field
                  class="input-count py-0 my-0"
                  type="number"
                  style="width: 35px"
                  v-model="slot.kid"
                  hide-details
                  @keypress="isNumber"
                  :error-messages="kidError"
                  :min="editMode ? Number(event.booked_kid) : 0"
                  :disabled="editMode && new Date(event.start) < new Date()"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="py-0">
            <v-row
              v-if="editMode"
              class="pl-10"
              style="min-height: 32px"
              align="center"
              justify="space-between"
            >
              <div>
                <i>Current {{ this.event.adult }}</i>
              </div>
            </v-row>
            <v-row
              v-if="editMode"
              justify="space-between"
              style="min-height: 32px"
              class="mt-5 pl-10"
              align="center"
            >
              <div>
                <i>Current {{ this.event.kid }}</i>
              </div>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0">
            <v-select
              :menu-props="{ bottom: true, offsetY: true }"
              v-model="slot.repeat"
              :items="repeatItems"
              :disabled="editMode"
            >
              <template #selection="{ item }">
                {{ item.text | localize }}
              </template>
              <template #item="{ item }">
                {{ item.text | localize }}
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="message" style="font-size: 12px; color: red">
          {{ message | localize }}
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row class="mb-0">
          <v-col>
            <v-btn
              v-if="
                editMode &&
                Number(event.booked_adult) == 0 &&
                Number(event.booked_kid) == 0
              "
              color="#E62076"
              block
              text
              style="height: 44px; text-transform: none"
              @click="isShowConfirmModal = true"
            >
              {{ "button_delete" | localize }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              color="#212121"
              block
              text
              style="height: 44px; text-transform: none"
              @click="visibility = false"
            >
              {{ "button_cancel" | localize }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              dark
              block
              style="
                height: 44px;
                background: #6abaa4;
                text-transform: none !important;
                border-bottom: 1px solid #4e8777;
              "
              v-if="!editMode"
              @click="createSlot()"
            >
              {{ "button_create" | localize }}
            </v-btn>
            <v-btn
              class="white--text"
              block
              :style="
                editMode && new Date(event.start) < new Date()
                  ? 'height: 44px;text-transform: none !important;'
                  : 'height: 44px;background: #6abaa4;text-transform: none !important;border-bottom: 1px solid #4e8777;'
              "
              v-else
              @click="updateSlot()"
              :disabled="editMode && new Date(event.start) < new Date()"
            >
              {{ "button_save" | localize }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <confirm-modal
      v-if="isShowConfirmModal"
      :visible="isShowConfirmModal"
      typeConfirm="deleteSlot"
      @choosed="onChoose"
    />
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minValue } from "vuelidate/lib/validators";
import eventsService from "@/requests/Events/eventsService.js";
import confirmModal from "@/components/OurAttractions/confirmModal";
import dateMixin from "../../mixins/dateMixin";

export default {
  components: {
    confirmModal,
  },
  mixins: [validationMixin, dateMixin],
  props: {
    event: {
      require: false,
    },
    editMode: {
      require: false,
      default: false,
    },
    addWithDates: {
      require: false,
      default: false,
    },
    visible: {
      type: Boolean,
      require: true,
    },
    attraction: {
      require: true,
    },
  },
  validations() {
    return {
      slot: {
        adult: {
          minValue: minValue(this.editMode ? this.event.booked_adult : 0),
        },
        kid: {
          minValue: minValue(this.editMode ? this.event.booked_kid : 0),
        },
        date: {
          required,
        },
        start_time: {
          required,
        },
        end_time: {
          required,
        },
      },
    };
  },
  data: () => ({
    slot: {
      adult: "0",
      kid: "0",
      repeat: 0,
      date: "",
      start_time: "",
      end_time: "",
    },
    menuTime: false,
    message: "",
    isShowConfirmModal: false,
    times: [],
    repeatItems: [
      {
        value: 0,
        text: "admin_prebooking_no_repeat_label",
      },
      {
        value: 1,
        text: "admin_prebooking_repeat_daily_label",
      },
      {
        value: 2,
        text: "admin_prebooking_repeat_weekly_label",
      },
      {
        value: 3,
        text: "admin_prebooking_repeat_monthly_label",
      },
    ],
    dateMenu: false,
  }),
  mounted() {
    if (this.editMode) {
      this.slot.adult = this.event.adult;
      this.slot.kid = this.event.kid;
      this.slot.date = this.event.start.substr(0, 10);
      this.slot.start_time = new Date(this.event.start)
        .toLocaleTimeString("en-gb", {
          hour12: false,
        })
        .slice(0, 5);
      this.slot.end_time = new Date(this.event.end)
        .toLocaleTimeString("en-gb", {
          hour12: false,
        })
        .slice(0, 5);
      if (!this.event.repeat) {
        this.slot.repeat = 0;
      } else {
        this.slot.repeat = Number(this.event.repeat);
      }
      console.log(this.times);
    } else if (this.addWithDates) {
      this.slot.adult = this.event.adult;
      this.slot.kid = this.event.kid;
      this.slot.date = new Date(
        new Date(this.event.start).getTime() -
          new Date(this.event.start).getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      this.slot.start_time = new Date(
        new Date(this.event.start).getTime() -
          new Date(this.event.start).getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(11, 5);
      this.slot.end_time = new Date(
        new Date(this.event.end).getTime() -
          new Date(this.event.end).getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(11, 5);
      this.slot.repeat = 0;
    }
    console.log(this.slot);
  },
  methods: {
    isNumber(evt) {
      const isNumber = isFinite(evt.key);

      if (!isNumber) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    onChoose(choose) {
      if (choose) {
        this.deleteSlot();
      }
      this.isShowConfirmModal = false;
    },
    async deleteSlot() {
      try {
        await eventsService.deleteEvent(this.event.uuid);
        this.$emit("modifiedEvents");
      } catch (e) {
        alert(e);
      }
    },
    async createSlot() {
      try {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          let data = {
            attraction_uuid: this.attraction.uid,
            adult: this.slot.adult,
            kid: this.slot.kid,
            started_at: new Date(
              `${this.slot.date} ${this.slot.start_time}`
            ).toISOString(),
            ended_at: new Date(
              `${this.slot.date} ${this.slot.end_time}`
            ).toISOString(),
          };
          if (this.slot.repeat == 0) {
            data.repeat = null;
          } else {
            data.repeat = this.slot.repeat;
          }
          let res = await eventsService.createEvent(data);
          if (res.result) {
            this.$emit("modifiedEvents");
          }
        }
      } catch (e) {
        if (e.response.status == 409) {
          this.message = "admin_prebooking_same_slot_validation";
        } else {
          alert(e);
        }
      }
    },
    getTimes() {
      console.log(this.slot);
      let times = [];
      console.log(this.slot.start_time);
      if (this.slot.start_time) {
        times.push(this.slot.start_time);
      }

      if (this.slot.end_time) {
        times.push(this.slot.end_time);
      }

      this.times = times.sort((a, b) => (a > b ? 1 : a < b ? -1 : 0));
    },
    async updateSlot() {
      try {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          let data = {
            attraction_uuid: this.attraction.uid,
            adult: this.slot.adult,
            kid: this.slot.kid,
            started_at: new Date(
              `${this.slot.date} ${this.slot.start_time}`
            ).toISOString(),
            ended_at: new Date(
              `${this.slot.date} ${this.slot.end_time}`
            ).toISOString(),
          };
          if (this.slot.repeat == 0) {
            data.repeat = null;
          } else {
            data.repeat = this.slot.repeat;
          }
          await eventsService.updateEvent(this.event.uuid, data);
          this.$emit("modifiedEvents");
        }
      } catch (e) {
        alert(e);
      }
    },
  },
  watch: {
    "slot.start_time": {
      handler() {
        this.getTimes();
      },
    },
    "slot.end_time": {
      handler() {
        this.getTimes();
      },
    },
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      },
    },
    dateError() {
      const errors = [];
      if (!this.$v.slot.date.$dirty) {
        return errors;
      }
      !this.$v.slot.date.required && errors.push("Date is required");
      return errors;
    },
    timeStartError() {
      const errors = [];
      if (!this.$v.slot.start_time.$dirty) {
        return errors;
      }
      !this.$v.slot.start_time.required &&
        errors.push("Start time is required");
      return errors;
    },
    timeEndError() {
      const errors = [];
      if (!this.$v.slot.end_time.$dirty) {
        return errors;
      }
      !this.$v.slot.end_time.required && errors.push("End time is required");
      return errors;
    },
    adultError() {
      const errors = [];
      if (!this.$v.slot.adult.$dirty) {
        return errors;
      }
      if (!this.$v.slot.adult.minValue) {
        errors.push("Count of adult attendees cannot be less than booked");
        return errors;
      }
      // !this.$v.slot.adult.required &&
      //   errors.push("Adult attendees is required");
      return errors;
    },
    kidError() {
      const errors = [];
      if (!this.$v.slot.kid.$dirty) {
        return errors;
      }
      if (!this.$v.slot.kid.minValue) {
        errors.push("Count of child attendees cannot be less than booked");
        return errors;
      }
      // !this.$v.slot.kid.required && errors.push("Child attendees is required");
      return errors;
    },
  },
};
</script>

<style>
.input-count input[type="number"]::-webkit-inner-spin-button {
  opacity: 1;
}
</style>