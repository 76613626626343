export default {
    methods: {
        adaptDate(date) {
            const dateFromDatabase = new Date(date);
            const userTimeZoneOffset = 360;
            const adaptedDate = new Date(
                dateFromDatabase.getTime() - userTimeZoneOffset * 60000
            );
            return adaptedDate;
        },
        convertToTimeZone(dateString, targetTimeZone) {
            const options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                timeZone: targetTimeZone,
            };

            const date = new Date(dateString);
            const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);

            return formattedDate;
        },
        formatToISO(start_date) {
            let date = `${new Date(this.adaptDate(start_date)).toLocaleDateString("en-GB", { day: "2-digit", year: "numeric", month: "2-digit", })}`
            return `${date.substring(6, 10)}-${date.substring(3, 5)}-${date.substring(0, 2)} ${new Date(this.adaptDate(start_date)).toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit", second: "2-digit", })}`
        }
    }
}