import requestService from '../requestService';

export default {
	async getEvents(params) {
		console.log(params)
		const response = await requestService.get(`events?${params}`);
		return response?.data
	},
	async createEvent(params) {
		const response = await requestService.post(`events`, params, {
			headers: {
				Authorization: `Bearer ${localStorage.businessToken}`,
			}
		})
		return response?.data
	},
	async updateEvent(event_uuid, params) {
		const response = await requestService.put(`/events/${event_uuid}`, params, {
			headers: {
				Authorization: `Bearer ${localStorage.businessToken}`,
			}
		})
		return response?.data
	},
	async getEventsAdmin(params) {
		console.log(params)
		const response = await requestService.getWithParams(`events`, params, {
			Authorization: `Bearer ${localStorage.businessToken}`,
		});
		return response?.data
	},
	async deleteEvent(uuid) {
		const response = await requestService.delete(`events/${uuid}`, {
			headers: {
				Authorization: `Bearer ${localStorage.businessToken}`
			}
		});
		return response?.data
	},
}